import {Component} from '@angular/core';
import {ICellRendererAngularComp} from '@ag-grid-community/angular';
import {ConnectedStatusEnum} from 'src/app/model/enums/connected-status.enum';
import {FormatService} from 'src/app/services/format.service';
import {isNotNullOrUndefined} from '../../../../../utils/utils.static';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatIconModule} from '@angular/material/icon';
import {NgIf} from '@angular/common';

@Component({
  selector: 'app-status-cell-renderer',
  templateUrl: './status-cell-renderer.component.html',
  styleUrls: ['./status-cell-renderer.component.scss'],
  standalone: true,
  imports: [NgIf, MatIconModule, MatTooltipModule]
})
export class StatusCellRendererComponent implements ICellRendererAngularComp {
  isValidated: boolean;
  isConnected: ConnectedStatusEnum;
  status: string;
  color = false;

  constructor(private readonly formatService: FormatService) {}

  agInit(params): void {
    this.isValidated = params.data.isValidated;
    this.isConnected = params.data.isConnected;

    if (isNotNullOrUndefined(this.isConnected) && this.isConnected === 3) {
      this.color = false;
      this.status = 'Mobile';
    } else {
      if (this.isValidated === undefined) {
        this.color = undefined;
      } else if (this.isValidated) {
        this.color = true;
      } else {
        this.color = false;
      }
      this.status = this.formatService.formatIsValidatedStatus(this.isValidated);
    }
  }

  refresh(): boolean {
    return false;
  }
}
