<ng-container *ngIf="isConnected === 3">
  <span class="badge rounded-pill badge-warning">
  <mat-icon [matTooltip]="this.status">
    smartphone
  </mat-icon>
  </span>
</ng-container>

<ng-container *ngIf="isConnected !== 3">
<span *ngIf="!color && color !== undefined" class="badge rounded-pill badge-danger">
  <mat-icon [matTooltip]="this.status">
    do_not_disturb_on_total_silence
  </mat-icon>
</span>
  <span *ngIf="color === undefined" class="badge rounded-pill badge-warning">
  <mat-icon [matTooltip]="this.status">
    help_outline
  </mat-icon>
</span>
  <span *ngIf="color" class="badge rounded-pill badge-success">
  <mat-icon [matTooltip]="this.status">
    done
  </mat-icon>
</span>
</ng-container>


