import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {YoungDTO} from '../model/dto/young.dto';
import {SimplePlayoutPresenceDto} from '../model/dto/simple-playout-presence.dto';
import {SimpleRegisteredResponsablePresenceDto} from '../model/dto/simple-registered-responsable-presence.dto';
import {SimpleJobEventListDTO} from '../model/dto/simple-job-event.dto';

@Injectable({
  providedIn: 'root'
})
export class ActivityInfosService {
  public eventName: string;
  public eventId: number;
  public programId: number;
  public programName: string;
  public centerId: number;
  public event: SimpleJobEventListDTO;

  selectedPlayout: SimplePlayoutPresenceDto;
  selectedRegisteredResponsable: SimpleRegisteredResponsablePresenceDto;

  private selection = [];

  private readonly dataCurrentUnregisteredYoungList = new BehaviorSubject([]);
  public currentUnregisteredYoungMessager = this.dataCurrentUnregisteredYoungList.asObservable();

  private readonly dataChosenYoungList = new BehaviorSubject([]);
  public chosenYoungMessager = this.dataChosenYoungList.asObservable();

  reset() {
    this.eventName = null;
    this.eventId = null;
    this.programId = null;
    this.programName = null;
    this.centerId = null;
    this.event = null;
    this.selection = [];
    this.changeCurrentUnregisteredYoungList([]);
    this.changeChosenYoungList([]);
  }

  changeCurrentUnregisteredYoungList(youngList: YoungDTO[]) {
    this.dataCurrentUnregisteredYoungList.next(youngList);
  }

  changeChosenYoungList(youngList: YoungDTO[]) {
    this.selection = youngList;
    this.dataChosenYoungList.next(youngList);
  }

  resetSelection() {
    this.selection = [];
  }

  getSelection() {
    return this.selection;
  }
}
